<template>
  <div class="component model-card">
    <!-- <a @click="goto('/model/' + name)"> -->
    <router-link :to="'/model/' + name">
      <div class="box is-radiusless">
        <img :src="image" alt="Image" :width="width" />
      </div>
    </router-link>
    <!-- </a> -->
  </div>
</template>

<script>
export default {
  name: "ModelCard",
  props: ["id", "name", "image", "width"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.model-card {
  padding: 0.5rem;

  img {
    //width: auto;
  }
  .box {
    text-align: center;
    background-color: #fff;
    border-radius: 5px !important;
    transition: 0.7s cubic-bezier(0.82, 0.1, 0, 0.99);
    z-index: 1;
    box-shadow: none;
    &:hover {
      box-shadow: 0px 15px 40px 5px #d8d8d8;
      cursor: pointer;
      transform: translateY(-2em);
    }
  }

  &:hover .box {
    //background-color: #f0f0f0;
  }
}
</style>