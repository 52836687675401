<template>
  <div class="studio">
    <div class="ux__menu">
      <!-- <b-slider
        size="is-medium"
        :min="1"
        :max="100"
        v-model="texture_repeat"
        @input="textureRepeatModify()"
        :tooltip="false"
        rounded
      >
      </b-slider> -->
    </div>

    <div
      id="scene"
      :class="{ over: isDragOver }"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    ></div>

    <div class="ux__tools is-flex">
      <b-button @click="modelDownload()" class="mr-2" type="is-primary" outlined
        >TAKE A PICTURE</b-button
      >

      <!-- <b-button @click="modelZoom(0)" class="mr-2" type="is-primary" outlined
        >ZOOM</b-button
      > -->

      <b-switch
        v-if="isDriveAutoreloadAuthorized"
        v-model="isDriveTextureAutoreload"
        @click.native="textureDriveAutoreload(!isDriveTextureAutoreload)"
        class=""
      >
      </b-switch>

      <model-card
        v-for="(model, id) in models_loaded"
        :key="model.name"
        :name="model.name"
        :id="id"
        :image="model.image"
        :width="50"
        class="module-card"
      ></model-card>
    </div>

    <div class="ux__sidebar">
      <hex-color-picker
        class="color-picker__module"
        :color="texture_color"
        @color-changed="handleColorChanged"
      ></hex-color-picker>

      <b-input v-model="texture_color" onclick="this.select()"></b-input>

      <div class="b-slider-box mt-6">
        <b-slider
          size="is-medium"
          :min="1"
          :max="100"
          v-model="texture_repeat"
          @input="textureRepeatModify()"
        >
        </b-slider>
      </div>
      <div class="b-slider-box">
        <b-slider
          size="is-medium"
          :min="1"
          :max="360 * 4"
          v-model="texture_rotate"
          @input="textureRotateModify()"
        >
        </b-slider>
      </div>
      <div class="b-slider-box">
        <b-slider
          size="is-medium"
          :min="1.0"
          :max="10000"
          v-model="texture_y"
          @input="textureTranslate()"
        >
        </b-slider>
      </div>
      <div class="b-slider-box">
        <b-slider
          size="is-medium"
          :min="1.0"
          :max="10000"
          v-model="texture_x"
          @input="textureTranslate()"
        >
        </b-slider>
      </div>
    </div>
  </div>
</template>

<script>
//import Layout from "@abstraktion/abstraktion-lib/visualize/layout/Layout";
//import Tool from "@abstraktion/abstraktion-lib/visualize/tool/Tool";

import Layout from "@abstraktion/abstraktion-lib/visualize/layout/Layout";
import Tool from "@abstraktion/abstraktion-lib/visualize/tool/Tool";
import ModelCard from "../components/ModelCard.vue";

// demo

//import ii from "../assets/models/talel/picture.jpg";
//import im from "../assets/models/talel/mask.png";
import it from "../assets/models/talel/texture.svg";
//import it from '../assets/model/shirt/abstraktion_square.png'
import "vanilla-colorful";

export default {
  name: "Studio",
  components: { ModelCard },
  data() {
    return {
      models: [
        "stiletto-white",
        "sneaker",
        "pair-of-thongs",
        "swimsuit-fashion",
        "trousers-classy",
        "notes",
        "cover-book",
        //"shirt",
        "mask",
        "poster-interior",
        "interior-walls",
        "cushion-interiors",
        "tshirt-woman-rock",
        "bra-swimming-pool-woman",
        //"bed",
        //"kendrick-sneaker-black-side",
        //"kendrick-ankle-boots",
      ],
      layout: null,
      tool: null,
      json_export: null,

      texture_repeat: 7,
      model_u: 20,
      model_v: 20,
      dot_size: 10,
      grid_color: 0xff0000,

      isDragOver: false,
      grid_display: false,

      drive_file_name: null,

      isDriveAutoreloadAuthorized: true,
      isDriveTextureAutoreload: false,
      driveAutoreloadFrequency: 1000 * 1,

      interval: null,

      models_loaded: [],

      // into localstorage
      texture_color: "ffffff",
      texture_rotate: 0,
      texture_x: 0,
      texture_y: 0,
    };
  },
  mounted() {
    this.modelsLoad();
    this.storageEventLookup();
    // get drive file name
    try {
      // store last file visited
      localStorage.setItem("model_path_id_latest", this.$route.path);
      this.drive_file_name = localStorage.getItem("model_texture_drive_id");
    } catch {
      console.log("error");
    }

    if (typeof process.env.VUE_APP_OPTION_DRIVE !== "undefined") {
      this.isDriveAutoreloadAuthorized = true;
    }

    this.init();
    //this.initLogic()

    if (this.drive_file_name) {
      console.log("lookup drive_file_name");
    }

    this.textureDriveAutoreload(this.isDriveTextureAutoreload);
  },
  created() {
    //this.layout.model.getGrid(0).lineUAdd(1)
  },
  methods: {
    storageEventLookup() {
      window.onstorage = () => {
        let p = localStorage.getItem("abstraktion_palette");
        //console.log(p);
        let colors = p.split(",");
        this.texture_color = colors[0];
        // this.palette[this.palette_selected] = {
        //   a: "#" + colors[0],
        //   b: "#" + colors[1],
        //   c: "#" + colors[2],
        //   d: "#" + colors[3],
        //   e: "#" + colors[4],
        //   f: "#" + colors[5],
        // };
      };
    },
    modelsLoad() {
      this.models.forEach((element) => {
        //console.log(element);

        let img = require("../assets/models/" + element + "/cover.jpg");
        this.models_loaded.push({
          image: img,
          name: element,
        });
      });
    },
    handleColorChanged(e) {
      if (this.layout) {
        this.texture_color = e.target.color.replace("#", "");
        let hextest = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");

        if (hextest.test(this.texture_color) && this.layout) {
          this.layout.getModel().setColor("#" + this.texture_color);
          try {
            localStorage.setItem("texture_color", this.texture_color);
          } catch (e) {
            console.log(" error ", e);
          }
        }
      }
    },
    textureRepeatModify() {
      if (this.layout) {
        this.layout.getModel().setRepeat(this.texture_repeat);

        try {
          localStorage.setItem("model_texture_repeat", this.texture_repeat);
        } catch (e) {
          console.log(" error ", e);
        }
      }
    },
    textureRotateModify() {
      if (this.layout) {
        this.layout
          .getModel()
          .setAngle(((Math.PI / 180) * this.texture_rotate) / 4);

        try {
          localStorage.setItem("texture_rotate", this.texture_rotate);
        } catch (e) {
          console.log(" error ", e);
        }
      }
    },
    textureTranslate() {
      if (this.layout) {
        this.layout
          .getModel()
          .setTranslateTexture(this.texture_x / 1400, this.texture_y / 1400);
        try {
          localStorage.setItem("texture_x", this.texture_x);
          localStorage.setItem("texture_y", this.texture_y);
        } catch (e) {
          console.log(" error ", e);
        }
      }
    },
    modelZoom(zoomLevel) {
      console.log("zoom " + zoomLevel);
    },

    textureDriveAutoreload(status) {
      if (status) {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          console.log("check modification");
          this.getFile();
        }, this.driveAutoreloadFrequency);
      } else {
        clearInterval(this.interval);
      }
    },

    modelLoad() {
      this.models.forEach((element) => {
        console.log(element);

        let x = require("../assets/models/" + element + "/cover.jpg");
        this.models_loaded.push({
          image: x,
          name: element,
        });
      });
    },

    /**
     * getFile
     * file_id
     */
    getFile() {
      // get file_id from localstorage
      let file_id = false;

      if (
        localStorage.getItem("model_texture_drive_id") &&
        localStorage.getItem("drive_access_token")
      ) {
        file_id = localStorage.getItem("model_texture_drive_id");

        let drive_access_token = localStorage.getItem("drive_access_token");

        let client = this.$gapi.getGapiClient();

        client.then((gapi) => {
          // client

          gapi.client.load("drive", "v3", () => {
            var file = gapi.client.drive.files.get({ fileId: file_id });

            file.execute(() => {
              // https://stackoverflow.com/questions/37860901/how-to-use-google-drive-api-to-download-files-with-javascript
              //var accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;// or this: gapi.auth.getToken().access_token;

              let xhr = new XMLHttpRequest();
              xhr.open(
                "GET",
                "https://www.googleapis.com/drive/v3/files/" +
                  file_id +
                  "?alt=media",
                true
              );
              xhr.setRequestHeader(
                "Authorization",
                "Bearer " + drive_access_token
              );
              xhr.responseType = "arraybuffer";
              xhr.onload = () => {
                //base64ArrayBuffer from https://gist.github.com/jonleighton/958841
                var base64 =
                  "data:image/png;base64," +
                  this.base64ArrayBuffer(xhr.response);

                try {
                  localStorage.setItem("model_texture", base64);
                } catch {
                  console.log("error");
                }
                var img = document.createElement("img");

                img.src = base64;

                // @todo use image as texture

                this.layout.setTexture(base64, this.texture_repeat);
                // localstorage save
                try {
                  localStorage.setItem("model_texture", base64);
                } catch (e) {
                  console.log(" error : texture size exceeds ", e);
                }
              };
              xhr.send();
            });
          });
        });
      }
    },

    /**
     * base64ArrayBuffer
     */
    base64ArrayBuffer(arrayBuffer) {
      var base64 = "";
      var encodings =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

      var bytes = new Uint8Array(arrayBuffer);
      var byteLength = bytes.byteLength;
      var byteRemainder = byteLength % 3;
      var mainLength = byteLength - byteRemainder;

      var a, b, c, d;
      var chunk;

      // Main loop deals with bytes in chunks of 3
      for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63; // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
      }

      // Deal with the remaining bytes and padding
      if (byteRemainder == 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + "==";
      } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + "=";
      }

      return base64;
    },

    filePicker() {
      console.log("@todo_call_drive");
    },

    gridDisplay() {
      this.tool.displayDotGrid(!this.grid_display);
    },

    dragOver() {
      //console.log('dragOver')
      this.isDragOver = true;
    },

    dragLeave() {
      //console.log('dragLeave')
      this.isDragOver = !true;
    },

    drop(e) {
      //console.log('drop',e)

      let files = e.dataTransfer.files;

      let file = files[0];

      if (file && file instanceof Blob) {
        let reader = new FileReader();
        reader.onload = (f) => {
          // f.target.result contains the base64 encoding of the image
          let src = f.target.result;

          // set texture
          this.layout.setTexture(src, this.texture_repeat);

          // localstorage save
          try {
            localStorage.setItem("model_texture", src);
          } catch (e) {
            console.log(" error : texture size exceeds ", e);
          }
        };
        reader.readAsDataURL(file);
      }

      this.isDragOver = !true;
    },

    modelDownload() {
      // https://codepen.io/looeee/pen/GdQpbd

      if (this.grid_display) this.gridDisplay(false); // not working

      var imgData;

      var saveFile = function (strData, filename) {
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          document.body.appendChild(link); //Firefox requires the link to be in the body
          link.download = filename;
          link.href = strData;
          link.click();
          document.body.removeChild(link); //remove the link when done
        } else {
          //location.replace(uri)
        }
      };

      try {
        const strMime = "image/jpeg";

        imgData = this.layout.renderer.domElement.toDataURL(strMime);

        saveFile(imgData.replace(strMime, strMime), "abstraktion.jpg");
      } catch (e) {
        console.log(e);
        return;
      }

      if (this.grid_display) this.gridDisplay(true);
    },

    operations() {
      console.log("operations");

      //this.layout.model.getGrid(0).rotate(45)
      //this.layout.model.getGrid(0).lineUAdd(1)

      this.grid_display = !this.grid_display;
      this.tool.displayGrid(this.grid_display);
    },

    modelSave() {
      console.log("modelSave");

      localStorage.setItem(
        "model_grid",
        JSON.stringify(this.layout.model.getGrid(0).export())
      );
    },

    modelReset() {
      localStorage.removeItem("model_picture");
      localStorage.removeItem("model_mask");
      localStorage.removeItem("model_grid");
      localStorage.removeItem("model_texture");
    },

    init() {
      // get route

      let model_id = this.$route.params.model_id;
      let model_picture, model_mask, model_grid;

      if (model_id == "studio") {
        if (
          localStorage.getItem("model_picture") &&
          localStorage.getItem("model_mask") &&
          localStorage.getItem("model_grid")
        ) {
          // load local storage data

          if (localStorage.getItem("model_picture") !== null) {
            model_picture = localStorage.getItem("model_picture");
          }
          if (localStorage.getItem("model_mask") !== null) {
            model_mask = localStorage.getItem("model_mask");
          }
          if (localStorage.getItem("model_grid") !== null) {
            model_grid = JSON.parse(localStorage.getItem("model_grid"));
          }
        } else {
          // redirect
          this.$router.push("/model");
        }
      } else {
        // check

        model_picture = require("../assets/models/" +
          model_id +
          "/picture.jpg");

        model_mask = require("../assets/models/" + model_id + "/mask.png");

        model_grid = require("../assets/models/" + model_id + "/model.json");
      }

      let model_texture;

      this.tool = new Tool();
      this.tool.displayDotGrid(this.grid_display);

      // repair
      //this.tool.setLineU()
      //this.tool.setLineV()

      let layout = new Layout({
        tool: this.tool,
        div_id: "scene",
        is_3d: !true,
        debug: !true,
        helpers: !true,
        dot_display: true,
        background: {
          color: 0xffffff,
        },
      });

      // image in localstorage ?

      if (localStorage.getItem("model_texture_repeat") !== null) {
        this.texture_repeat = parseInt(
          localStorage.getItem("model_texture_repeat")
        );
        layout.model.setRepeat(this.texture_repeat);
      }

      // if (localStorage.getItem("model_picture") !== null) {
      //   model_picture = localStorage.getItem("model_picture");
      // } else {
      //   model_picture = ii;
      // }
      // if (localStorage.getItem("model_mask") !== null) {
      //   model_mask = localStorage.getItem("model_mask");
      // } else {
      //   model_mask = im;
      // }
      if (localStorage.getItem("model_texture") !== null) {
        model_texture = localStorage.getItem("model_texture");
      } else {
        model_texture = it;
      }
      // if (localStorage.getItem("model_grid") !== null) {
      //   model_grid = JSON.parse(localStorage.getItem("model_grid"));
      // } else {
      //   model_grid = null;
      // }

      if (localStorage.getItem("texture_color") !== null) {
        this.texture_color = localStorage.getItem("texture_color");
      } else {
        this.texture_color = "ffffff";
      }
      if (localStorage.getItem("texture_rotate") !== null) {
        this.texture_rotate = JSON.parse(
          localStorage.getItem("texture_rotate")
        );
      } else {
        this.texture_rotate = 0;
      }
      if (localStorage.getItem("texture_x") !== null) {
        this.texture_x = JSON.parse(localStorage.getItem("texture_x"));
      } else {
        this.texture_x = 0;
      }
      if (localStorage.getItem("texture_y") !== null) {
        this.texture_y = JSON.parse(localStorage.getItem("texture_y"));
      } else {
        this.texture_y = 0;
      }

      layout.setPicture(model_picture);
      layout.setModel(
        model_mask,
        model_texture,
        {
          dot: {
            color: this.grid_color,
            size: this.dot_size,
          },
          repeat: this.texture_repeat,
          uv: {
            u: this.model_u,
            v: this.model_v,
          },
          import: model_grid,
        },
        () => {
          //console.log( 'callback' )

          localStorage.setItem(
            "model_grid",
            JSON.stringify(this.layout.model.getGrid(0).export())
          );

          // apply
          this.layout.getModel().setColor("#" + this.texture_color);
        }
      );

      //      window.l = layout;

      this.layout = layout;
    },

    initLogic() {
      /**
       * mouse interaction
       */

      this.layout.mouse.setMouseDragCallback(() => {
        // if not moving dots
        if (this.layout.controls.enabled) {
          //console.log('cb')

          // left click
          if (this.layout.mouse.isClickLeft()) {
            //

            if (this.layout.keyboard.isAlt()) {
              // rotate all

              //this.modelLayerRotate( this.layout.getLayerActive() , this.layout.mouse.dragX()/100 + this.layout.mouse.dragY()/1000 )
              this.layout.model.getGrid(
                this.layout.getLayerActive()
              ).mesh.material.map.rotation =
                this.layout.mouse.dragX() / 100 +
                this.layout.mouse.dragY() / 1000;
            } else {
              if (this.layout.keyboard.isControl()) {
                // rotate material
                //this.modelMaterialRotate( this.layout.getLayerActive() , this.layout.mouse.dragX()/10 + this.layout.mouse.dragY()/100 )
              }

              if (this.layout.keyboard.isShift()) {
                // texture size

                let zy = this.layout.mouse.dragY() / 30;

                if (zy < 0) zy = -zy;

                let zx = this.layout.mouse.dragX() / 1000;

                if (zx < 0) zx = -zx;

                //this.modelTextureSize       (  this.layout.getLayerActive() , zx + zy )
              }

              if (this.layout.keyboard.isNop()) {
                // default
                //this.modelMaterialMove      ( this.layout.getLayerActive() , -this.layout.mouse.dragX()/100 , this.layout.mouse.dragY()/100 )
              }
            }

            //

            //this.modelLayerRepeat       ( 0 , this.layout.mouse.dragY() )
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
#scene {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 1s;
  &.over {
    filter: brightness(50%);
  }
}

#export {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 16rem;
  height: 8rem;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  font-size: 0.5rem;
  word-wrap: break-word;
  overflow-y: auto;
  text-align: left;
}

.ux {
  &__menu {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    //padding: 2rem;

    ul {
      display: flex;
      max-width: 80%;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      height: auto;
      position: absolute;

      li {
        &:first-child {
          font-weight: bold;
        }
        margin-right: 2rem;
        cursor: pointer;
        transition: all 1s;
        &:hover {
          opacity: 0.5;
        }
        margin: 1rem;
      }
    }

    .b-slider {
      margin: 0px;
    }
  }

  &__tools {
    z-index: 100;
    align-items: center;
    position: absolute;
    bottom: 0px;
    padding: 1rem;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: all 1s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.85);
    }
    ul {
      text-transform: uppercase;
      font-size: 1rem;

      li {
        &:first-child {
          font-weight: 100;
        }
        margin-right: 2rem;
        cursor: pointer;
        transition: all 1s;
        &:hover {
          opacity: 0.5;
        }
        margin: 0 1rem;
      }
    }
  }

  &__sidebar {
    height: 100%;
    min-width: 14em;
    position: fixed;
    right: 0;
    top: 150px;
    padding: 0.75em;
    background-color: rgba(255, 255, 255, 0);
    transition: all 1s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.85);
    }
  }
}
</style>